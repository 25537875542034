<template>
   <div class="dropDownData">
      <div ref="search" class="search">
         <el-card class="box-card" shadow="never" :body-style="{padding:'10px'}">
            <el-form :inline="true" :model="searchForm" label-width="80px">
               <!--          <search-open-btn :searchShow="searchShow" @searchOpen="searchOpen"></search-open-btn>-->
               <el-form-item label="所属模块">
                  <el-select v-model="searchForm.goodsPdMod" :size="$store.state.size" placeholder="所属模块" clearable>
                     <el-option v-for="(item, index) of ModeList" :key="index" :label="item.sysMiName" :value="item.sysMiId"></el-option>
                  </el-select>
               </el-form-item>
               <el-form-item label="主值名称">
                  <el-input v-model="searchForm.goodsPdName" :size="$store.state.size" placeholder="主值名称" clearable @keyup.enter.native="searchSubmit(true)"></el-input>
               </el-form-item>
            </el-form>
            <el-divider></el-divider>
            <el-button v-if="hasPermission('2-9-a')" type="primary" :size="$store.state.size" @click="searchSubmit(true)" icon="el-icon-search">查询</el-button>
            <el-button v-if="hasPermission('2-9-b')" type="default" plain :size="$store.state.size" @click="resetSubmit" icon="el-icon-delete">清空</el-button>
            <!--        <el-button v-if="hasPermission('2-1-c')" type="primary" :size="$store.state.size" @click="saveOpen" icon="el-icon-circle-plus-outline">新增</el-button>-->
         </el-card>
      </div>
      <el-table :data="tableData" :height="tableHeight" v-loading="tableLoading" @sort-change="sortChange" border :size="this.$common.tableLineHeight()" :header-cell-style="{background:'#f5f7fa',color:'#515a6e', fontWeight:'900'}" style="width: 100%; margin-top: 10px">
         <el-table-column prop="modName" align="center" label="所属模块">
            <template slot-scope="scope">
               {{ scope.row.modName == '品名维护'?'品种维护': scope.row.modName }}
            </template>
         </el-table-column>
         <el-table-column prop="goodsPdName" align="center" label="下拉值名称">
         </el-table-column>
         <!--      <el-table-column-->
         <!--          align="center"-->
         <!--          label="主值状态">-->
         <!--        <template slot-scope="scope">-->
         <!--          <el-switch-->
         <!--              v-model="scope.row.goodsPdStatus"-->
         <!--              @change="updateStatus(scope.row)"-->
         <!--              :active-value="0"-->
         <!--              :inactive-value="1"-->
         <!--              active-text="启用"-->
         <!--              inactive-text="停用">-->
         <!--          </el-switch>-->
         <!--        </template>-->
         <!--      </el-table-column>-->
         <el-table-column align="center" label="操作" width="260">
            <template slot-scope="scope">
               <el-button v-if="hasPermission('2-9-d')" type="text" size="small" @click="totalsubCurrentPage = 1;searchForm.page = 1;subvalue(scope.row);" icon="el-icon-document">展开子值</el-button>
               <el-button v-if="hasPermission('2-9-c')" type="text" size="small" @click="saveOpen(scope.row)" icon="el-icon-circle-plus-outline">新增子值</el-button>

               <!--          <el-button v-if="hasPermission('2-1-e')" type="text" size="small" @click="editOpen(scope.row)" icon="el-icon-edit-outline">编辑</el-button>-->
               <!--          <el-button v-if="hasPermission('2-1-f')" type="text" size="small" @click="delOpen(scope.row)" icon="el-icon-delete">删除</el-button>-->
            </template>
         </el-table-column>
      </el-table>
      <pagination :current-page="currentPage" :total="total" @size-change="handleSizeChange" @current-change="handleCurrentChange">
      </pagination>

      <!--    保存模态框-->
      <assembly-dialog :title="dialogTitle" :visible.sync="dialogVisibleSave" width="30%" @before-close="handleClose">
         <el-form :model="saveForm" :rules="saveRules" ref="saveForm" label-width="80px">
            <!--        <el-form-item label="新增类别" prop="goodsPdType">-->
            <!--          <el-radio v-model="saveForm.goodsPdType" :label="0" :disabled="editShow">主值</el-radio>-->
            <!--          <el-radio v-model="saveForm.goodsPdType" :label="1" :disabled="editShow">子值</el-radio>-->
            <!--        </el-form-item>-->
            <!--        <el-form-item label="所属模块" prop="goodsPdMod">-->
            <!--          <el-select v-model="saveForm.goodsPdMod" :size="$store.state.size" placeholder="所属模块" clearable @change="changeCustomerType">-->
            <!--            <el-option v-for="(item, index) of ModeList" :key="index" :label="item.sysMiName" :value="item.sysMiId"></el-option>-->
            <!--          </el-select>-->
            <!--        </el-form-item>-->
            <!--        <el-form-item label="编号" prop="goodsPdCode">-->
            <!--          <el-input v-model="saveForm.goodsPdCode" :size="$store.state.size" placeholder="编号" clearable></el-input>-->
            <!--        </el-form-item>-->
            <!--        <el-form-item label="内容" prop="goodsPdName" v-if="saveForm.goodsPdType==0">-->
            <!--          <el-input v-model="saveForm.goodsPdName" :size="$store.state.size" placeholder="内容" clearable></el-input>-->
            <!--        </el-form-item>-->
            <el-form-item label="制造商" prop="customerId">
               <el-select v-model="saveForm.customerId" :size="$store.state.size" placeholder="制造商" clearable @change="getBrandList">
                  <el-option v-for="e in manufacturerList" :key="e.sysCiId" :label="e.sysCiShortName" :value="e.sysCiId"></el-option>
               </el-select>
            </el-form-item>
            <el-form-item label="品牌" prop="goodsPdBrandId">
               <el-select v-model="saveForm.goodsPdBrandId" :size="$store.state.size" placeholder="品牌" clearable>
                  <el-option v-for="e in brandList" :key="e.goodsBiId" :label="e.goodsBiName" :value="e.goodsBiId"></el-option>
               </el-select>
            </el-form-item>

            <el-form-item label="名称" prop="goodsPdName" v-show="showGoodsPdName">
               <el-input v-model="saveForm.goodsPdName" :size="$store.state.size" placeholder="名称" clearable></el-input>
            </el-form-item>
            <el-form-item label="内容" prop="goodsPdValue">
               <el-input v-model="saveForm.goodsPdValue" :size="$store.state.size" placeholder="内容" clearable></el-input>
            </el-form-item>
            <!--        <el-form-item label="所属主值" prop="goodsPdValue"  v-if="saveForm.goodsPdType==1">-->
            <!--          <el-select v-model="saveForm.goodsPdParentId" :size="$store.state.size" placeholder="所属主值" clearable >-->
            <!--            <el-option v-for="(item, index) of customerType" :key="index" :label="item.goodsPdName" :value="item.goodsPdId"></el-option>-->
            <!--          </el-select>-->
            <!--        </el-form-item>-->
         </el-form>
         <span slot="footer" class="dialog-footer">
            <el-button :size="$store.state.size" @click="clearForm('saveForm')">清 空</el-button>
            <el-button :size="$store.state.size" type="primary" @click="saveSubmit('saveForm')">确 定</el-button>
         </span>
      </assembly-dialog>
      <!--    查看子值模态框 -->
      <assembly-dialog top='9vh' title="子值列表" :visible.sync="subvaluePage" width="60%" @before-close="subvaluePage = false;searchForm.goodsPdParentId=''">
         <el-table :data="tableDatasubvalue" :height="tableHeight" v-loading="tableLoading" @sort-change="sortChange" border :size="this.$common.tableLineHeight()" :header-cell-style="{background:'#f5f7fa',color:'#515a6e', fontWeight:'900'}" style="width: 100%; margin-top: 10px">
            <el-table-column prop="customerName" align="center" label="所属制造商">
            </el-table-column>
            <el-table-column prop="brandName" align="center" label="所属品牌">
            </el-table-column>
            <el-table-column prop="goodsPdName" align="center" label="下拉值名称">
            </el-table-column>
            <el-table-column prop="goodsPdValue" align="center" label="下拉值内容">
            </el-table-column>
            <el-table-column align="center" label="子值状态" width="150">
               <template slot-scope="scope">
                  <el-switch v-model="scope.row.goodsPdStatus" @change="updateStatus(scope.row)" :active-value="0" :inactive-value="1" active-text="启用" inactive-text="停用">
                  </el-switch>
               </template>
            </el-table-column>
            <el-table-column align="center" label="操作" width="230">
               <template slot-scope="scope">
                  <el-button v-if="hasPermission('2-9-e')" type="text" size="small" @click="editOpen(scope.row)" icon="el-icon-edit-outline">编辑</el-button>
                  <el-button v-if="hasPermission('2-9-f')" type="text" size="small" @click="delOpen(scope.row)" icon="el-icon-delete">删除</el-button>
               </template>
            </el-table-column>
         </el-table>
         <pagination :current-page="totalsubCurrentPage" :total="totalsubvalue" @size-change="handleSize" @current-change="handleCurrent">
         </pagination>
      </assembly-dialog>
   </div>
</template>

<script>
import pagination from "@/components/assembly-pagination/index";
import getPinYinUtil from '@/assets/js/get-pinyin-util.js'
import AssemblyDialog from "components/assembly-dialog";

export default {
   name: "dropDownData",
   components: {
      AssemblyDialog,
      pagination
   },
   watch: {
      //监听头部展开收起变化
      '$store.state.isHeader'() {
         this.setTableHeight()
      },
      //监听快速导航展开收起变化
      '$store.state.fastNav'() {
         this.setTableHeight()
      },
      //监听搜索区域变化改变列表
      searchShow() {
         this.setTableHeight()
      },
      'saveForm.sysCiShortName'() {
         let shortCode = getPinYinUtil.ConvertPinyin(this.saveForm.sysCiShortName)
         this.saveForm.sysCiShortCode = shortCode
      },
      'searchForm.page'(val) {
         this.$nextTick(() => {
            this.totalsubCurrentPage = val;
         })
      }
      //监听制造商下拉框变化
      // 'saveForm.customerId'() {
      //   console.log(1111111111)
      //   this.$set(this.saveForm, 'goodsPdBrandId', '')
      //   this.brandList = []
      // },
   },
   data() {
      return {
         dialogTitle: '新增',
         //搜索区域展开收起开关
         searchShow: false,
         //表格高度
         tableHeight: 0,
         //加载
         tableLoading: false,
         //表格数据
         tableData: [],
         //当前页面
         currentPage: 1,
         //总数
         total: 0,
         //子值列表
         tableDatasubvalue: [],
         //子值当前页面
         totalsubCurrentPage: 1,
         //子值总数
         totalsubvalue: 0,
         ModeList: [],
         //制造上列表
         manufacturerList: [],
         //品牌列表
         brandList: [],
         //搜索表单
         searchForm: {
            goodsPdCode: '',
            goodsPdName: '',
            order: '',
            orderByColumn: '',
            goodsPdParentId: '',
            page: 1,
            pageSize: this.$store.state.initPageSize,
            goodsPdMod: ""
         },
         //保存模态框开关
         dialogVisibleSave: false,
         //是编辑框回现
         editShow: false,
         //保存表单
         saveForm: {
            goodsPdType: '',
            goodsPdMod: '',
            goodsPdCode: '',
            goodsPdId: '',
            goodsPdName: '',
            goodsPdValue: '',
            customerId: '',
            goodsPdBrandId: '',
            customerName: '',
            brandName: ''
         },
         //保存表单验证
         saveRules: {
            // goodsPdType: [{required: true, message: '请选择新增类别', trigger: 'change'}],
            // goodsPdMod: [
            //   {required: true, message: '请选择所属模块', trigger: 'change'}
            // ],
            // goodsPdCode: [
            //   {required: true, message: '请输入编号', trigger: 'blur'}
            // ],
            customerId: [
               { required: true, message: '请选择所属制造商', trigger: 'change' }
            ],
            goodsPdBrandId: [
               { required: true, message: '请选择所属品牌', trigger: 'change' }
            ],
            goodsPdName: [
               { required: true, validator: this.$verification.messageGoodsPdName, trigger: 'blur' }
            ],
            goodsPdValue: [
               { required: true, validator: this.$verification.messageGoodsPdValue, trigger: 'blur' }
            ],
         },
         //  显示客户类型编码
         showCode: false,
         //  子值列表模态框显示
         subvaluePage: false,
         //子值名称显示
         showGoodsPdName: false,
         isSave: 1, //编辑查询传1，查询传0
         isAdd: false
      }
   },
   mounted() {
      //初始化计算表格区域高度
      this.setTableHeight();
      this.searchSubmit();
      //  初始化所属模块列表
      this.getModeList();
      //获取制造商列表
      this.manufacturer();
   },
   methods: {
      //判断权限是否存在
      hasPermission(item) {
         return this.$common.hasPermission(item);
      },
      /****表格高度计算 start********************************************************************************/
      //计算表格区域高度
      setTableHeight() {
         this.$nextTick(() => {
            this.tableHeight = this.$common.getHeight(this.$refs.search.clientHeight)
            window.onresize = () => {
               return (() => {
                  this.tableHeight = this.$common.getHeight(this.$refs.search.clientHeight)
               })();
            };
         })
      },
      /****表格高度计算 end********************************************************************************/
      /****搜素区域 start********************************************************************************/
      //搜索展开收起事件
      searchOpen(searchShow) {
         this.searchShow = searchShow
      },
      //搜索表单提交
      searchSubmit(flag) {
         if (flag) {
            this.searchForm.page = 1
         }
         // let user = JSON.parse(sessionStorage.getItem('userInfo'));
         // this.searchForm.userid = user.sysUiId;
         this.tableLoading = true
         this.$server.productDroplistByPage(this.searchForm).then((res) => {
            this.tableLoading = false
            if (res.ok) {
               this.tableData = res.data.records
               this.total = res.data.total
            }
         }).catch(e => {
            this.tableLoading = false
            console.log(e)
         })
      },
      //搜索表单重置
      resetSubmit() {
         this.$common.clearFormValue(this.searchForm)
         this.searchForm.page = 1;
         this.searchForm.pageSize = this.$store.state.initPageSize;
         this.searchSubmit()
      },
      /****搜素区域 end********************************************************************************/
      /****保存区域 start********************************************************************************/
      clearBrand() {
         this.brandList = [];
         this.saveForm.goodsPdBrandId = '';
      },
      //保存表单  提交
      saveOpen(row) {
         this.isAdd = true;
         this.dialogTitle = '新增'
         this.saveForm.goodsPdType = 1
         this.saveForm.goodsPdParentId = row.goodsPdId
         this.saveForm.goodsPdMod = row.goodsPdMod
         this.dialogVisibleSave = true
         if (row.goodsPdName == "膜层") {
            this.showGoodsPdName = true
         } else {
            this.saveForm.goodsPdName = row.goodsPdName
         }
      },
      //模态框关闭前回调
      handleClose() {
         this.showCode = false
         this.dialogVisibleSave = false
         this.editShow = false;
         this.isAdd = false;
         this.clearForm('saveForm')
      },
      //保存提交
      saveSubmit(formName) {
         this.$refs[formName].validate((valid) => {
            if (valid) {
               this.$server.productDroplistUpdate(this.saveForm).then(res => {
                  if (res.ok) {
                     this.$message.success(res.msg)
                     if (this.subvaluePage) {
                        this.subvalue(this.saveForm, true)
                     } else {
                        this.searchForm.goodsPdParentId = ''
                        this.searchSubmit()
                     }
                  } else {
                     this.$message.error(res.msg)
                  }
                  this.dialogVisibleSave = false
                  this.editShow = false
                  this.showCode = false
                  this.isAdd = false;
                  this.clearForm('saveForm')
               }).catch(e => {
                  console.log(e)
               })
            } else {
               console.log('error submit!!');
               return false;
            }
         });
      },
      //清空表单
      clearForm(formName) {
         this.$refs[formName].resetFields();
         this.showCode = false
         this.$common.clearFormValue(this.saveForm)
         this.brandList = []
      },
      //编辑模态框开启
      async editOpen(row) {
         this.dialogTitle = '编辑'
         this.saveForm = JSON.parse(JSON.stringify(row))
         this.dialogVisibleSave = true
         this.editShow = true
         if (row.customerId) {
            await this.getBrandList(row.customerId)
         }
         this.saveForm.goodsPdBrandId = row.goodsPdBrandId
      },
      //编辑子值停启用
      updateStatus(row) {
         this.$server.updateStatusDroplist({ id: row.goodsPdId }).then(res => {
            if (res.ok) {
               this.$message.success(res.msg)
               if (this.subvaluePage) {
                  this.subvalue(this.searchForm, true)
               } else {
                  this.searchSubmit()
               }
            } else {
               this.$message.error(res.msg)
            }
         }).catch(e => {
            console.log(e)
         })
      },
      /****保存区域 end********************************************************************************/
      /****获取制造商列表 start********************************************************************************/
      manufacturer() {
         let user = JSON.parse(sessionStorage.getItem('userInfo'));
         this.$server.getList({ sysCiType: 1, userid: user.sysUiId }).then((res) => {
            if (res.ok) {
               this.manufacturerList = res.data
            }
         }).catch(e => {
            console.log(e)
         })
      },
      /****获取制造商列表 end********************************************************************************/
      /****获取品牌列表 start**************************************************************************/
      //获取品牌列表
      async getBrandList(id) {
         this.brandList = []
         this.isSave = this.isAdd == true ? 0 : 1
         this.$set(this.saveForm, 'goodsPdBrandId', '')
         await this.$server.getBrandList({ goodsBiCustomerId: id, isSave: this.isSave }).then(res => {
            if (res.ok) {
               this.brandList = res.data
            }
         }).catch(e => {
            console.log(e)
         })
      },
      /****获取品牌列表 end**************************************************************************/
      /****删除区域 start********************************************************************************/
      delOpen(row) {

         this.$confirm('确认删除该条数据, 是否继续?', '提示', {
            confirmButtonText: '确定',
            cancelButtonText: '取消',
            type: 'warning'
         }).then(() => {
            this.$server.productDroplistDelete({ id: row.goodsPdId }).then(res => {
               if (res.ok) {
                  this.$message.success(res.msg)
                  if (this.subvaluePage) {
                     this.subvalue(this.searchForm, true)
                  } else {
                     this.searchSubmit()
                  }
               } else {
                  this.$message.error(res.msg)
               }
            }).catch(e => {
               console.log(e)
            })
         }).catch(() => { });
      },
      /****删除区域 end********************************************************************************/

      /****查看子值 star********************************************************************************/
      subvalue(row, flag) {
         if (!flag) {
            this.searchForm.goodsPdParentId = row.goodsPdId
         }
         this.subvaluePage = true
         this.tableLoading = true
         let user = JSON.parse(sessionStorage.getItem('userInfo'));
         this.searchForm.userid = user.sysUiId;
         this.$server.productDroplistByPage(this.searchForm).then((res) => {
            this.tableLoading = false
            if (res.ok) {
               this.tableDatasubvalue = res.data.records
               console.log("tableDatasubvalue", this.tableDatasubvalue)
               this.totalsubvalue = res.data.total
            }
         }).catch(e => {
            this.tableLoading = false
            console.log(e)
         })
      },
      //清空子值搜索
      subvalueClose() {
         this.subvaluePage = false
         this.$common.clearFormValue(this.searchForm)
         this.searchForm.page = 1;
         this.searchForm.pageSize = this.$store.state.initPageSize;
      },
      /****查看子值 end********************************************************************************/
      /****初始化下拉列表 start********************************************************************************/
      //根据所属模块查询主值列表
      changeCustomerType(row) {
         this.getCustomerType(row)
      },
      //主值列表
      getCustomerType(row) {
         this.$server.grincipalValueList({ goodsPdMod: row }).then((res) => {
            if (res.ok) {
               this.customerType = res.data
            }
         }).catch(e => {
            console.log(e)
         })
      },
      //所属模块
      getModeList() {
         this.$server.getModeList().then((res) => {
            if (res.ok) {
               this.ModeList = res.data.map(item => {
                  if (item.sysMiName == '品名维护') {
                     return { ...item, sysMiName: '品种维护' }
                  }
                  return item
               })
            }
         }).catch(e => {
            console.log(e)
         })
      },
      /****初始化下拉列表 end********************************************************************************/
      handleSizeChange(val) {
         this.searchForm.pageSize = val
         this.searchSubmit()
      },
      handleCurrentChange(val) {
         this.searchForm.page = val
         this.searchSubmit()
      },
      handleSize(val) {
         this.searchForm.pageSize = val
         this.subvalue(this.searchForm, true)
      },
      handleCurrent(val) {
         console.log(this.totalsubCurrentPage)
         this.searchForm.page = val
         this.subvalue(this.searchForm, true)
      },
      //排序
      sortChange(row) {
         console.log('排序排序排序', JSON.stringify(row))
         if (row.prop == 'sysCiTypeStr') {
            this.searchForm.orderByColumn = 'sysCiId'
         } else {
            this.searchForm.orderByColumn = row.prop
         }
         if (row.order == 'ascending') {
            this.searchForm.order = 'asc'
         } else if (row.order == 'descending') {
            this.searchForm.order = 'desc'
         } else {
            this.searchForm.order = ''
         }
         this.searchSubmit()
         this.searchForm.page = 1;
      },

      onSubmit() {
         console.log('submit!');
      }
   },
}
</script>

<style lang="scss">
.dropDownData {
   .search {
      //表单每行底部外边距设置
      .el-form-item {
         margin-bottom: 10px;
      }

      .el-select {
         width: 200px;
      }

      .el-input {
         width: 200px;
      }

      //分割线外边距设置
      .el-divider--horizontal {
         margin: 0 0 10px 0;
      }
   }

   .el-dialog {
      .el-select {
         width: 100%;
      }
   }
}
</style>

<style scoped>
::v-deep .el-dialog {
   display: flex;
   flex-direction: column;
   margin: 0 !important;
   position: absolute;
   top: 50%;
   left: 50%;
   transform: translate(-50%, -50%);
   max-height: calc(100% - 30px);
   max-width: calc(100% - 30px);
}
</style>
